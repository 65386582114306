import imageCompression from "browser-image-compression";
import Bugsnag from "@bugsnag/js";

import Http from "./Http";

class ImageService extends Http {
  static $displayName = "ImageService";

  resizeLoadedImages(ids) {
    return this.patch("/files/images/resize", ids);
  }

  static async fileToBase64(file, toCompress = false, fileSizeLimit = 1.5) {
    const options = {
      maxSizeMB: fileSizeLimit,
      useWebWorker: true,
      alwaysKeepResolution: false,
    };

    let modifiedFile;

    if (
      file &&
      toCompress === true &&
      file.size / 1024 / 1024 >= fileSizeLimit
    ) {
      try {
        Bugsnag.leaveBreadcrumb("Compressing image", {
          file,
        });
        const compressedFile = await imageCompression(file, options);
        modifiedFile = compressedFile;
      } catch (e) {
        Bugsnag.notify(e);
        modifiedFile = file;
      }
    } else {
      modifiedFile = file;
    }

    return new Promise((resolve, reject) => {
      Bugsnag.leaveBreadcrumb("Converting image to base64", {
        file: modifiedFile,
      });
      const reader = new FileReader();
      reader.readAsDataURL(modifiedFile);
      reader.onload = function () {
        resolve(reader.result);
      };
      reader.onerror = reject;
    });
  }
}

export default ImageService;
