import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

import CreditCard from "./components/CreditCard";
import SubscriptionModal from "./components/SubscriptionModal";
import SubscriberModal from "./components/CreditCard/SubscriberModal";

import { useSocketIo } from "../../hooks/useSocketIo";
import { subscriberModals } from "./components/CreditCard/SubscriberModal/modals";
import { ACTIVE, ACTIVE_STATUS, INACTIVE } from "./constant/subscription";
import { EVENTS } from "../../constants/socketEvents";
import { useUpdateProfile } from "../../../pages/profile/hooks/useProfile";
import { useStatefullProvider } from "../../hooks/useProvider";
import { WaitSubscribtionContext } from "../../contexts/CustomErrorMessage";
import { useCheckIsNeedToLogout } from "../../../pages/auth/hooks/useCheckIsNeedToLogout";

const Subscription = () => {
  const [isNeedToLogout] = useCheckIsNeedToLogout();

  const updateProfile = useUpdateProfile();
  const { state } = useLocation();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [chosenSubscription, updateChosenSubscription] = useState(null);
  const [isOpenNextModal, updateIsOpenNextModal] = useState(false);
  const [subscriberModal, updateSubscriberModal] = useState(null);

  const handleToggleModal = () => setIsOpenModal((isOpen) => !isOpen);

  useEffect(() => {
    if (state?.fromComplitedProfile) return;

    if (isNeedToLogout) {
      setIsOpenModal(true);
    } else {
      setIsOpenModal(false);
    }
  }, [isNeedToLogout, state?.fromComplitedProfile]);

  const handleChoseSubscribe = useCallback((subscription) => {
    updateChosenSubscription(subscription);
  }, []);

  const handleNextModal = useCallback((toggleClose) => {
    updateIsOpenNextModal((isOpen) => (toggleClose ? false : !isOpen));
  }, []);

  const handleNextStep = useCallback(() => {
    handleToggleModal();
    handleNextModal();
  }, [handleNextModal]);

  const handleBackStep = useCallback(() => {
    handleToggleModal();
    handleNextModal();
  }, [handleNextModal]);

  const handleToogleModal = useCallback(
    (activated) => {
      handleNextModal(true);
      updateSubscriberModal(subscriberModals[activated]);
    },
    [handleNextModal]
  );

  const [, updateWaitSubscrb] = useStatefullProvider(WaitSubscribtionContext);

  const subscriptionSocketEvents = useMemo(
    () => ({
      events: [
        {
          event: EVENTS.PAYMENT_EVENT,
          handleEvent: (data) => {
            const {
              subscription: { status },
            } = data;
            handleToogleModal(status === ACTIVE_STATUS ? ACTIVE : INACTIVE);
            updateProfile();
            updateWaitSubscrb({ wait: false });
          },
        },
      ],
    }),
    [handleToogleModal, updateProfile, updateWaitSubscrb]
  );

  useSocketIo(subscriptionSocketEvents);

  return (
    <>
      <SubscriptionModal
        isOpen={isOpenModal}
        handleToggleModal={handleToggleModal}
        chosenSubscription={chosenSubscription}
        handleChoseSubscribe={handleChoseSubscribe}
        handleNextStep={handleNextStep}
      />
      <CreditCard
        isOpen={isOpenNextModal}
        handleIsOpen={handleNextModal}
        onClose={() => updateIsOpenNextModal(false)}
        onChangeStep={handleBackStep}
        chosenSubscription={chosenSubscription}
      />
      {subscriberModal && (
        <SubscriberModal
          subscriberModal={subscriberModal}
          onClose={() => updateSubscriberModal(null)}
          onError={() => updateIsOpenNextModal(true)}
        />
      )}
    </>
  );
};

export default Subscription;
